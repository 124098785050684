import React from "react"
import bandWave from "../assets/images/bg/banner4-wave1.svg"
import waterMark2 from "../assets/images/bg/watermark2-bg.png"
import waterMark3 from "../assets/images/bg/watermark1-bg.png"
import { Link } from "react-scroll"
import waterMark from "../assets/images/bg/watermark2-bg.png"
import tranformWay from "../../assets/illustrations/tranformativeWay.png"
const Index = () => {
  return (
    <>
      <div className="banner-section4" style={{ minHeight: "60vh" }}>
        <img
          src={bandWave}
          className="banner4-wave1 img-fluid"
          alt="Wave image"
        />
        <img
          src={waterMark2}
          className="banner4-wave2 img-fluid"
          alt="Wave image 1"
        />

        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8 col-sm-10">
              <div
                className="section-title3 primary4 text-cener"
                style={{ marginBottom: "12px" }}
              >
                <span>-SPA Cottage Education-</span>
                <p>Browse the latest education offering from SPA Cottage</p>
                <div className="button-group gap-5">
                  <Link
                    to="litCollection"
                    smooth={true}
                    offset={-110}
                    duration={100}
                    className="eg-btn btn--primary4 btn--lg"
                    style={{ cursor: "pointer" }}
                  >
                    View Collection
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="team-section2 pb-120 position-relative overflow-hidden"
        id="litCollection"
      >
        <img src={waterMark3} className="watermark1-bg" alt="watermark" />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8 col-sm-10">
              <div className="section-title3 primary4 text-cener">
                <span style={{ marginTop: "10px" }}>-Our Collection-</span>
                <p>Browse the latest in SPA Cottage Education</p>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center g-4">
            <div className="col-xl-6 col-lg-6 col-md-8 col-sm-10">
              <div
                className="single-team2 style-2 wow fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay="1s"
                style={{
                  padding: "20px",
                  paddingBottom: "5px",
                  paddingTop: "0px",
                }}
              >
                <div className="team-image">
                  <img
                    src={tranformWay}
                    alt="book 3"
                    style={{
                      paddingTop: "0px",
                      marginBottom: "0px",
                      height: "100%",
                    }}
                  />
                  <div className="social-area gap-3">
                    <ul className="social-links d-flex justify-content-center align-items-center flex-row gap-3">
                      <li>
                        <a
                          href="https://www.amazon.co.uk/Transformative-Way-Creative-Writing-Resources/dp/B0DHV3N6KW"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="bx bxl-amazon"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="section-title3 primary4 text-cener"
                  style={{
                    marginBottom: "0px",
                    paddingTop: "10px",
                    cursor: "pointer",
                  }}
                >
                  <a
                    href="https://www.amazon.co.uk/Transformative-Way-Creative-Writing-Resources/dp/B0DHV3N6KW"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>Purchase</span>{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="service-section4 pt-120 pb-120 position-relative overflow-hidden"
            style={{ paddingTop: "5px", paddingBottom: "25px" }}
          >
            <img
              src={waterMark}
              className="watermark1-bg"
              alt="watermark background"
            />
            <div className="container">
              <div className="content">
                <div class="proj-details-single">
                  <div
                    className="challenge-box"
                    style={{ paddingLeft: "5px", marginTop: "5px" }}
                  >
                    <div
                      class="section-title3 primary4 text-start"
                      style={{ marginBottom: "5px" }}
                    >
                      <span>
                        - The Transformative Way: Creative Prison Writing with
                        Resources
                      </span>
                    </div>
                    <p className="para">
                      <i>‘The Transformative Way’</i> presents 52 ‘tried and
                      tested’ workshop-resources based on years of experience by
                      Pamela Brown, Pearson Award winning Facilitator and
                      Lecturer in Creative Writing. The resources are proven to
                      empower prison-writers throughout the textbook, outlining
                      the dynamic practically of{" "}
                      <i> ‘The Transformative Way’.</i> The dynamic maximizes
                      the ‘journey’ for the prison writer entering the workshop
                      environment, combatting literacy issues (if any) and
                      progressing while receiving validation. The ‘creative
                      journey’ is achieved through gaining independence as a
                      creative thinker and writer. Shadowing the textbook, Brown
                      relates, ‘are those whom I have witnessed making a
                      successful rehabilitation with family, society,
                      relationships and the workplace: this is breakout,
                      breakthrough and real release as their earlier previous
                      release into creativity.’
                    </p>
                    <p className="para">
                      The 52 resources are grounded in Brown’s vast background
                      projected onto the genre of prison literature—a tradition
                      that encompasses centuries of writing. The textbook
                      includes samples and examples from every genre with
                      discussion of classics such as{" "}
                      <i>
                        ‘The Ballad of Reading Gaol’, ‘Lifer’, ‘Borstal Boy’,
                        ‘Pain of Confinement: Prison Diaries’{" "}
                      </i>
                      and American poet, Jimmy Santiago Baca’s{" "}
                      <i>‘A Place to Stand’.</i>
                    </p>
                    <p className="para">
                      The textbook makes the case for wider acknowledgement of
                      the prison writing and literature genre within the academy
                      related to psychology, law, criminology and ‘post trauma
                      individuation’. The psychological aspect indicates
                      directions for development during what is{" "}
                      <i> ‘an emergency situation’</i> as the participant in
                      workshops moves transitionally into the post traumatic
                      state and their writing comes to fruition. The process can
                      bring about post traumatic resolutions, visible for the
                      writer through what is produced. Crucially, creative
                      writing has delved into the conscious and subconscious and
                      sign posts <i>The Transformative Way. </i>
                      <i>‘Individuation’ </i> as explored by modern psychology,
                      psychoanalysis, and group psychotherapeutic therapy is
                      mirrored in the collective workshop process. In this zone,
                      the prisoner is not solitary as when in the prison lockup.
                      Prison-writing facilitators will identify with Brown’s
                      background and engagement within creative writing as
                      artistic discipline demanding education, talent,
                      achievements, experience, and depths of knowledge vital to
                      directing each workshop event, incident and outcome.
                    </p>
                    <p className="para">
                      <b> Pamela Mary Brown</b> poet, author, performer,
                      Writer-in-Residence HMP Magilligan; Editor Time In
                      magazine; Co-Editor All In magazine; (former) Creative
                      Writing Tutor-Assessor, North West Regional College; MA in
                      Creative Writing (distinction)., Open University; BA
                      (Hons.)., English Literature & Creative Writing, Teesside
                      University; Extra-Mural Studies/Community Drama (with
                      commendation), University of Ulster; Media Studies, Foyle
                      Arts Centre, Derry. Pearson Silver Award for Further
                      Education Lecturer of the Year (2023).{" "}
                      <a
                        href="https://www.pamelamarybrown.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {" "}
                        www.pamelamarybrown.com
                      </a>{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Index
